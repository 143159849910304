@import '~antd/dist/antd.css';

.App {
  text-align: center;
  font-family: 'tt_norms_thin', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgba(40, 33, 33, 0.62);
}

.links {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 9999;
}
.links a{
  padding-right: 15px;
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.banner {
  background-color: rgba(42, 37, 37, 0.56);

  position: relative;

  width: 100%;
  height: 100vh;
  top: 0;

  /* Will not allow the video to overflow the
  container */
  overflow: hidden;

  /* Centering the container's content vertically
  and horizontally */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

#video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
}

.hero-text {
  position: absolute;
  top: 42%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  text-align: center;
  width: 85%;
}

.hero-text h2, h4, h5{
  color: white;
}

.title-box {
  padding-top: 50px;
  padding-left: 30px;
}

.title {
  float: left;

  height: 50px;
  z-index: 9;
}

.title h2{
  color: white;
  font-weight: 700;
}

.hero-text img{
  width: 50px;
}
.hero-text a{
  color: red;
  padding-right: 10px;
}

.main {
  height: 100%;
  display: block;
  width: 100%;
  padding-top: 20px;
  padding-left: 15%;
  padding-right: 15%;
  text-align: center;

}

.main-content {
  width: 90%;
  background: #FFFFFF;
  padding: 80px 0;
  margin: 0 auto 0px;
  position: relative;
  border-radius: 5px;
  display: block;
  max-width: 1400px;
  box-shadow: 0px 10px 30px rgba(5,5,5,.1);
}
.main h1 {
  display: block;
  text-align: center;
  position: relative;
  margin: 0 0 70px;
  font-size: 1.8em;
  letter-spacing: 6px;
  text-transform: uppercase;
  font-weight: 800;
}

.main h1:after {
  content: "";
  width: 30px;
  height: 3px;
  background: #7d887d;
  position: absolute;
  left: 50%;
  bottom: -15px;
  display: block;
  margin: 0 0 0 -15px;
}

.main .post {
  padding-right: 70px;
  padding-left: 70px;
  padding-bottom: 100px;
}
.main .post img {
  height: 300px;
  object-fit: cover;
}
