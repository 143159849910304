.menu {
    top: 0;
    padding-top: 45px;
    position: fixed;
    color: white;
    width: 100%;
    padding-right: 45px;
    padding-left: 45px;
    z-index: 9999;
}

.menu-black {
    top: 0;
    padding-top: 45px;
    position: fixed;
    color: white;
    width: 100%;
    padding-right: 45px;
    padding-left: 45px;
    z-index: 9999;
    background: rgb(0, 0, 0);
}
.menu-black a {
    left: 40px;
    text-align: left;
    color: white;
    font-weight: 900;
    font-size: 20px;
}


.menu a{
    left: 40px;
    text-align: left;
    color: white;
    font-weight: 900;
    font-size: 20px;

}

.nav{
    float: right;
    text-align: right;
    color: white;
}
.nav a{
    text-align: right;
    color: white;
    font-weight: 700;
    font-size: 14px;
    padding-right: 30px;
}
.nav a:hover{

    color: rgb(2, 86, 250);
}