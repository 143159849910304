.footer {
    position: relative;
    font-size: 13px;
    z-index: 1;
    background: #141617;
    width: 100%;
    overflow: hidden;
    color: #fff;
    padding-top: 50px ;
}

.footer h1{
    margin: 0 0 40px;
    font-size: 1.8em;
    letter-spacing: 6px;
    color: #fff;
    font-weight: 900;
}

.footer text{
    color: #fff;
    padding-left: 10px;
    font-size: 17px;
}
.footer a{
    color: #fff;
    padding-left: 10px;
    font-size: 17px;
}
.footer a:link{
    color: #fff;
}

.copyright {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 30px;
    border-top: 1px solid rgb(101, 97, 97);
}